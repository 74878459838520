import React from "react";
import styled from "styled-components";
import { Headline40 } from "./styles/Headings";
import { Eyebrows12 } from "./styles/Eyebrows";
import { Body20 } from "./styles/BodyTypes";
import ArrowButton from "./ArrowButton";
import MoveInDiv from "./MoveInDiv";

const Row = styled.span`
  display: block;
  margin: 11px 0 0;
`;

const SectionSnippet = ({ title, subtitle, link, children }) => {
  return (
    <MoveInDiv threshold={[0]} triggerOnce={true}>
      <Headline40 theme={{ margins: "0 0 0" }}>{title}</Headline40>
      {subtitle && <Eyebrows12>{subtitle}</Eyebrows12>}
      <Body20 theme={{ margins: "19px 0 0" }}>
        {children}
        <Row>
          <ArrowButton attrs={link}>More</ArrowButton>
        </Row>
      </Body20>
    </MoveInDiv>
  );
};

export default SectionSnippet;
