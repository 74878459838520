import React, { PureComponent } from "react";
import styled from "styled-components";
import { Headline80 } from "./styles/Headings";
import Container from "./styles/Container";
import { black1, textBlack1, white } from "./styles/Colors";
import Image from "./Image";
import PrimaryButton from "./PrimaryButton";
import OutlinedText from "./styles/OutlinedText";
import { mediaMedium } from "./styles/variables";
import { graphql, Link, useStaticQuery } from "gatsby";
import uniqueId from "lodash/uniqueId";
import { Linear, TimelineMax, TweenLite } from "gsap/dist/gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

const Outline = styled.div`
  margin-bottom: 31px;
`;

const Wrapper = styled.div`
  background: ${black1};
  padding-bottom: 96px;
  overflow: hidden;
  position: relative;

  @media (${mediaMedium}) {
    padding-bottom: 0;
  }
`;

const Box = styled.div`
  padding: 92px 0 64px;
  max-width: 334px;
  width: 100%;

  @media (${mediaMedium}) {
    padding: 42.6% 0;
  }
`;

const ContainerGrid = styled(Container)`
  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    grid-gap: calc(100% * 1 / 12 + 42px);
  }

  @media (max-width: 1000px) {
    grid-gap: calc(100% * 1 / 12);
    grid-template-columns: 55% 45%;
  }
`;

const Top = styled.div`
  order: 2;
`;

const Mags = styled(Image)`
  height: 100%;
  display: none;
  max-height: none !important;
  overflow: visible !important;

  &:nth-child(2) {
    transform: translateY(-25%);
  }

  @media (${mediaMedium}) {
    display: block;
  }
`;

const MagsMobile = styled(Image)`
  display: block;
  left: -30%;
  width: 160% !important;
  max-width: 160% !important;
  max-height: none !important;
  transform: translateX(-10%);

  &:nth-child(2) {
    transform: translateX(5%);
    margin-top: 23px;
  }

  @media (${mediaMedium}) {
    display: none;
  }
`;

const Bottom = styled.div`
  position: relative;
  max-width: 100%;

  @media (${mediaMedium}) {
    overflow: hidden;
    align-self: stretch;
    height: 100%;
  }
`;

const Desktop = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  grid-gap: 35px;
  width: 100%;
  max-height: 1038px;

  @media (${mediaMedium}) {
    display: grid;
  }

  @media (max-width: 1000px) {
    max-height: 700px;
  }
`;

const Mobile = styled.div`
  @media (${mediaMedium}) {
    display: none;
  }
`;

const Trigger = styled.div`
  position: absolute;
  top: 15%;
  width: 100%;
  height: 1px;
`;

class MagWallSection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      controllerSM: null,
      sceneVRight: null,
      sceneVLeft: null,
      sceneHTop: null,
      sceneHBottom: null,
      id: uniqueId("mag-wall-")
    };
  }

  async componentDidMount() {
    this.ScrollMagic = await import("scrollmagic");
    TweenLite.defaultOverwrite = "none";

    ScrollMagicPluginGsap(this.ScrollMagic, TweenLite, TimelineMax);

    this.initAnimation();
  }

  componentWillUnmount() {
    const {
      controllerSM,
      sceneVRight,
      sceneVLeft,
      sceneHTop,
      sceneHBottom
    } = this.state;

    if (controllerSM) controllerSM.destroy(true);
    if (sceneVRight) sceneVRight.destroy(true);
    if (sceneVLeft) sceneVLeft.destroy(true);
    if (sceneHTop) sceneHTop.destroy(true);
    if (sceneHBottom) sceneHBottom.destroy(true);
  }

  initAnimation() {
    const VerticalLeft = TweenLite.to(
      `#mags-vertical .gatsby-image-wrapper:nth-child(1)`,
      1,
      {
        translateY: "-32%",
        ease: Linear.ease
      }
    );

    const VerticalRight = TweenLite.to(
      `#mags-vertical .gatsby-image-wrapper:nth-child(2)`,
      1,
      {
        translateY: "15%",
        ease: Linear.ease
      }
    );

    const HorizontalTop = TweenLite.to(
      `#mags-horizontal .gatsby-image-wrapper:nth-child(1)`,
      1,
      {
        translateX: "0%",
        ease: Linear.ease
      }
    );

    const HorizontalBottom = TweenLite.to(
      `#mags-horizontal .gatsby-image-wrapper:nth-child(2)`,
      1,
      {
        translateX: "-5%",
        ease: Linear.ease
      }
    );

    // init controller
    const controllerSM = new this.ScrollMagic.Controller();

    // build scene
    const sceneVLeft = new this.ScrollMagic.Scene({
      triggerElement: `#mag-wall-trigger`,
      duration: 2000,
      offset: -100
    })
      .setTween(VerticalLeft)
      .addTo(controllerSM);

    const sceneVRight = new this.ScrollMagic.Scene({
      triggerElement: `#mag-wall-trigger`,
      duration: 2100,
      offset: -100
    })
      .setTween(VerticalRight)
      .addTo(controllerSM);

    const sceneHTop = new this.ScrollMagic.Scene({
      triggerElement: `#mag-wall-trigger`,
      duration: 1200,
      offset: 150
    })
      .setTween(HorizontalTop)
      .addTo(controllerSM);

    const sceneHBottom = new this.ScrollMagic.Scene({
      triggerElement: `#mag-wall-trigger`,
      duration: 1200,
      offset: 150
    })
      .setTween(HorizontalBottom)
      .addTo(controllerSM);

    this.setState({
      sceneVLeft,
      sceneVRight,
      controllerSM,
      sceneHTop,
      sceneHBottom
    });
  }

  render() {
    let { images } = this.props;
    return (
      <Wrapper>
        <Trigger id="mag-wall-trigger" />
        <ContainerGrid>
          <Top>
            <Box>
              <Headline80 theme={{ color: white }}>
                WE WORK <br /> WITH THE
              </Headline80>
              <Outline>
                <OutlinedText
                  color={textBlack1}
                  fill={textBlack1}
                  stroke={white}
                  component={Headline80}
                >
                  BEST
                </OutlinedText>
              </Outline>
              <PrimaryButton
                attrs={{
                  to: "/contact/",
                  state: { from: "publisher" },
                  as: Link
                }}
              >
                Get Started
              </PrimaryButton>
            </Box>
          </Top>

          <Bottom>
            <Mobile id="mags-horizontal">
              <MagsMobile fluid={images.mobileTop.childImageSharp.fluid} />
              <MagsMobile fluid={images.mobileBottom.childImageSharp.fluid} />
            </Mobile>

            <Desktop id="mags-vertical">
              <Mags fluid={images.desktopLeft.childImageSharp.fluid} />
              <Mags fluid={images.desktopRight.childImageSharp.fluid} />
            </Desktop>
          </Bottom>
        </ContainerGrid>
      </Wrapper>
    );
  }
}

const MagWall = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLeft: file(relativePath: { eq: "8-mag-wall-1.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopRight: file(relativePath: { eq: "8-mag-wall-2.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTop: file(relativePath: { eq: "8-mag-wall-mobile-1.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileBottom: file(relativePath: { eq: "8-mag-wall-mobile-2.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <MagWallSection images={data} />;
};

export default MagWall;
