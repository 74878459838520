import React from "react";

const DecorationRightLarge = () => {
  return (
    <svg width="236px" height="1488px" viewBox="0 0 236 1488">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-502.000000, 0.000000)" stroke="#184179">
          <polyline
            className="line"
            points="413.462305 -75.5 719.695392 116.644036 719.695392 1560.46889 1385.4623 1965.5"
          />
          <polyline
            className="line"
            points="397.462305 -107.5 703.695392 84.7381781 703.695392 1529.27044 1369.4623 1934.5"
          />
          <polyline
            className="line"
            points="430.462305 -45.5 736.695392 146.738178 736.695392 1591.27044 1402.4623 1996.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default DecorationRightLarge;
