import React from "react";
import PublishersTwoColumn from "../PublishersTwoColumn";
import HeroMetrics from "../HeroMetrics";
import HeroTestimonial from "../HeroTestimonial";
import { Headline32 } from "../styles/Headings";
import { white } from "../styles/Colors";
import HeroBlueCTA from "../HeroBlueCTA";
import { graphql, Link, useStaticQuery } from "gatsby";
import MagWall from "../MagWall";

const HomeDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      logoTestimonial: file(relativePath: { eq: "11-entrepeneur-logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "11-testimonial-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "11-testimonial-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <MagWall />

      <PublishersTwoColumn />

      <HeroMetrics
        description="Paid to Publisher and Brand Partners"
        number="150"
        start="100"
        prefix="$"
        suffix="M+"
      />

      <HeroTestimonial
        media={images}
        author="Bill Shaw | President @ Entrepreneur"
      >
        <Headline32 theme={{ margins: "18px 0 35px", color: white }}>
          StackCommerce has been a great partner in helping us launch and grow
          our ecommerce business. We tried running things in-house beforehand
          and had very hit-or-miss results.
        </Headline32>
        <Headline32 theme={{ margins: "18px 0", color: white }}>
          But since working with StackCommerce, we’ve gotten incredible traction
          and now have a reliable revenue stream we can count on each month.
        </Headline32>
      </HeroTestimonial>

      <HeroBlueCTA
        title="LET’S WORK"
        outline="TOGETHER"
        description="Become a brand or publisher partner today, and start earning tomorrow."
        link={{ to: "/contact/", state: { from: "publisher" }, as: Link }}
      />
    </>
  );
};

export default HomeDefer;
