import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import SectionSnippet from "./SectionSnippet";
import { mediaMedium } from "./styles/variables";
import AnimatedLineSvg from "./AnimatedLineSvg";
import DecorationRightLarge from "./snippets/DecorationRightLarge";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    margin-bottom: 86px;

    @media (${mediaMedium}) {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.div`
  margin: 0 0 26px 0;

  @media (${mediaMedium}) {
    margin: 0 0 41px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Grid = styled(Container)`
  max-width: 480px;
  padding-top: 93px;
  padding-bottom: 86px;
  position: relative;
  overflow: hidden;

  @media (${mediaMedium}) {
    max-width: 980px;
    padding-top: 160px;
    padding-bottom: 160px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20%;
  }
`;

const Line = styled.span`
  @media (${mediaMedium}) {
    position: absolute;
    z-index: 2;
    width: 30%;
    height: 100%;
    top: 26px;
    left: 26.5%;
    bottom: 0;
  }
`;

const PublishersTwoColumn = () => {
  return (
    <Grid>
      <Line>
        <AnimatedLineSvg static={true} duration={1000} triggerOffset={50}>
          <DecorationRightLarge />
        </AnimatedLineSvg>
      </Line>
      <Column>
        <Row>
          <SectionSnippet
            subtitle="For Publishers"
            link={{ to: "/publishers/shops/" }}
            title="SHOPS"
          >
            We build, power, and merchandise your branded Shop and commerce
            articles so you can jumpstart or grow your commerce business
            risk-free.
          </SectionSnippet>
        </Row>
        <Row>
          <SectionSnippet
            subtitle="For Publishers"
            link={{ to: "/publishers/affiliate/" }}
            title="AFFILIATE"
          >
            Not ready for a branded Shop? Earn high-margin affiliate commissions
            with access to our exclusive offers and commerce content driving to
            our marketplaces.
          </SectionSnippet>
        </Row>
        <Row>
          <SectionSnippet
            subtitle="For Publishers"
            link={{ to: "/publishers/stack-media/" }}
            title="STACKMEDIA"
          >
            Our performance-based advertising solution is the easiest and most
            streamlined way to scale your revenue from sponsored content,
            newsletters, and native ads.
          </SectionSnippet>
        </Row>
      </Column>
      <Column>
        <Row>
          <SectionSnippet
            link={{ to: "/brands/shops/" }}
            title="SHOPS"
            subtitle="For Brands"
          >
            Grow your revenue and gain valuable brand exposure by getting listed
            in hundreds of publisher shops in a matter of clicks.
          </SectionSnippet>
        </Row>
        <Row>
          <SectionSnippet
            subtitle="For Brands"
            link={{ to: "/brands/stack-media/" }}
            title="STACKMEDIA"
          >
            Reach your target audience and drive leads straight to your site
            with performance-based sponsored content at scale.
          </SectionSnippet>
        </Row>
      </Column>
    </Grid>
  );
};

export default PublishersTwoColumn;
